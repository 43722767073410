<template>
  <div>
    <w-top-bar>
      <div>Mi Cuenta</div>
    </w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div
        class="flex justify-between h-16 px-1 transition-opacity duration-200 ease-in-out print:hidden"
      >
        <div class="text-gray-800">
          <div class="text-lg">Bienvenido!</div>
          <div class="text-sm">
            Por ser la primera vez que inicias sesión, es necesario que cambies
            tu contraseña.
          </div>
        </div>
        <div
          v-if="status === 'changed'"
          class="text-sm font-semibold text-green-500"
        >
          La contraseña se actualizo!
        </div>
      </div>
      <div class="mb-2">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <ValidationProvider
              rules="required|password:@confirm|min:5"
              v-slot="{ errors }"
            >
              <label
                for="input"
                class="block text-sm font-medium leading-5 text-gray-700"
                >Contraseña</label
              >
              <input
                class="block w-full px-2 py-1 mt-1 border rounded ring-1 ring-black ring-opacity-5 boder-gray-400 form-input sm:text-sm sm:leading-5"
                type="password"
                required
                v-model="password"
              />
              <span class="text-xs text-red-500">{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              name="confirm"
              rules="required|min:5"
              v-slot="{ errors }"
            >
              <div class="mt-4">
                <label
                  for="input"
                  class="block text-sm font-medium leading-5 text-gray-700"
                  >Repetir Contraseña</label
                >
                <input
                  class="block w-full px-2 py-1 mt-1 border rounded ring-1 ring-black ring-opacity-5 boder-gray-400 form-input sm:text-sm sm:leading-5"
                  type="password"
                  required
                  v-model="confirmation"
                />
                <span class="text-xs text-red-500">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <div class="flex justify-end mt-4">
              <button
                :disabled="invalid"
                class="transition-colors duration-200 ease-in-out btn"
                :class="{
                  'btn-green': status === 'changed',
                  'btn-gray': status === 'busy' || invalid,
                  'btn-blue': !status && !invalid,
                }"
              >
                <transition
                  leave-active-class="transition-all duration-300 ease-out"
                  enter-active-class="transition-all duration-200 ease-in"
                  enter-class="opacity-0 sm:scale-95"
                  enter-to-class="opacity-100 sm:scale-100"
                  leave-to-class="opacity-0 sm:scale-95"
                  leave-class="opacity-100 sm:scale-100"
                >
                  <span
                    class="transition-all duration-200 ease-in-out"
                    v-if="!status"
                    >Guardar</span
                  >
                  <span v-if="status === 'busy'">
                    <svg
                      class="w-5 h-5 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="status === 'changed'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      class="w-5 h-5"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                </transition>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// import { makeFindMixin, makeGetMixin } from 'feathers-vuex'; 
import { mapActions, mapGetters } from 'vuex';
import { extend } from 'vee-validate';
import { min } from 'vee-validate/dist/rules';

// Override the default message.
extend('min', {
  ...min,
  message: 'La contraseña debe tener como mínimo 6 caracteres.'
});


extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Las contraseñas deben coincidir.'
});

export default {
  name: 'profile',
  data() {
    return {
     password:"",
     confirmation:"",
     status: null
    }
  },
  mixins: [
    // makeFindMixin({ service: 'branchs'  }),
    // makeGetMixin({ service: 'branchs' }),
  ], 
  watch: {
  },
  computed: {
  ...mapGetters('auth', ['user']),
  },
  methods: {
  ...mapActions("users", {patch: "patch"}),
  async onSubmit(){
    console.log(this.user._id)
    this.status = "busy"
    await this.patch([this.user._id, {password: this.password, passwordUpdated: true}])
    this.status = "changed"
    // console.log(res)
  }
  },
};
</script>

<style lang="scss" scoped></style>
